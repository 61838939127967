import React, { useState } from 'react';

import {useParams} from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  useGetTimeseriesRunConfigQuery,
  useDeleteTimeseriesRunMutation
} from '../../services/ReportingService'
import { ReadOnlyYaml } from '../../features/config_yaml/ConfigYaml';

interface ParamTypes {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles(
  {
    root: {
      minWidth: 275,
    },
    listroot: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    }
  })
);

export default function TimeseriesRun(){
  let { id } = useParams<ParamTypes>();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { data, error, isLoading } = useGetTimeseriesRunConfigQuery(id)
  const [deleteRun, deleteResult] = useDeleteTimeseriesRunMutation()

  const deleteData = deleteResult.data
  const deleteLoading = deleteResult.isLoading
  const deleteSent = !deleteResult.isUninitialized

  const classes = useStyles();

  if (isLoading){
    return (
      <CircularProgress />
    );
  } else if (error){
    console.log(error)
    return (
      <div>
        <h3>Error getting run</h3>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  } else if (data) {
    const {
      created,
      last_read,
      hash,
      feature_records,
      aggregateseries_records,
      metamodel_records,
      run_id
    } = data

    let run_id_el = (<p>No run id provided</p>)
    if (run_id){
        run_id_el = (
          <div>
            <Typography variant="h5" component="h2">
              Run ID:
            </Typography>
            {ReadOnlyYaml(run_id)}
          </div>
        );
    }

    let delete_info_el = (<p>Delete Series</p>);
    if (deleteLoading){
      delete_info_el = (
        <div>
          Deleting - this may take a long time... <CircularProgress />
        </div>
      );
    }
    else if (deleteData){
      delete_info_el = (
        <div>
          Deleted {deleteData.features_deleted} featues, {deleteData.aggregateseries_deleted} aggregate series, and {deleteData.metamodels_deleted} metamodels
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2">
              Run {hash}
            </Typography>
            <div className={classes.listroot}>
              <List component="nav">
                <ListItem>
                  <ListItemText primary={`Created ${(new Date(created)).toLocaleString()}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Last Read ${(new Date(last_read)).toDateString()}`} />
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemText primary={`Feature Series Documents: ${feature_records.toLocaleString()}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Aggregate Series Documents: ${aggregateseries_records.toLocaleString()}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Model Objects: ${metamodel_records.toLocaleString()}`} />
                </ListItem>
              </List>
            </div>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setDeleteDialogOpen(true)}
              disabled={deleteSent}
            >
              {delete_info_el}
            </Button>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => {setDeleteDialogOpen(false);}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this series?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {setDeleteDialogOpen(false);}} color="primary">
                  No keep it
                </Button>
                <Button onClick={() => {setDeleteDialogOpen(false);deleteRun(hash);}} color="secondary">
                  Yes kill it
                </Button>
              </DialogActions>
            </Dialog>
          </CardActions>
        </Card>
        {run_id_el}
      </div>
    );
  } else {
    return (<p>No request sent to server</p>);
  }
}