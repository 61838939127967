import React from 'react';
import {Switch, Route} from "react-router-dom";

import BacktestStubList from "../features/backtests/BacktestsStubList"
import TimeseriesRunList from "../features/timeseries_runs/TimeseriesRunList"
import TimeseriesRun from "../features/timeseries_runs/TimeseriesRun"
import TearSheet from "../features/backtests/TearSheet"
import Production from "../features/production/Production"
import OrthogonalModelReport from "../features/orthogonal/OrthogonalModelReport"
import OrthogonalList from "../features/orthogonal/OrthogonalList"
import OrthogonalConfig from "../features/orthogonal/OrthogonalConfig"


export default function Routes() {
  return (
    <Switch>
      <Route path="/backtest/tear/:id" children={<TearSheet />} />
      <Route path="/orthogonal/model/:id" children={<OrthogonalModelReport />} />
      <Route path="/orthogonal/config/:id" children={<OrthogonalConfig />} />
      <Route path="/orthogonal" children={<OrthogonalList />} />
      <Route path="/timeseries_runs/config/:id" children={<TimeseriesRun />} />
      <Route path="/backtests" children={<BacktestStubList/>} />
      <Route path="/timeseries_runs" children={<TimeseriesRunList />} />
      <Route path='/production' children={<Production />} />
      <Route path='/real_production' children={<Production />} />
    </Switch>
  );
}