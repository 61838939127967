import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';

import Tooltip from '@material-ui/core/Tooltip';

import { useGetOrthogonalModelsQuery } from '../../services/ReportingService'


const useStyles = makeStyles({

 dataGrid: {
    width: "1200px"
  }
});

export default function OrthogonalList() {
  // Using a query hook automatically fetches data and returns query values
  const { data, error, isLoading } = useGetOrthogonalModelsQuery()
  const classes = useStyles();

  if (isLoading){
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } else if (error){
    console.log(error)
    return (
      <div>
        <h3>Error getting orthogonal model list</h3>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  } else if (data) {
    const columns: GridColDef[] = [
      {
        field: "calculation_hash",
        headerName: "Graphs",
        width: 280,
        renderCell: (params) => {
          return (
            <Link to={`/orthogonal/model/${params.value}`}>
              {params.value}
            </Link>
          )
        }
      },
      {
        field: 'created_ts',
        headerName: 'Created',
        width: 220,
        valueFormatter: (params: GridValueFormatterParams) => (new Date(String(params.value))).toLocaleString()
      },
      {
        field: 'as_of_dt',
        headerName: 'As of',
        width: 120,
        valueFormatter: (params: GridValueFormatterParams) => (new Date(String(params.value))).toLocaleDateString("en-US")
      },
      {
        field: 'output',
        headerName: 'Output',
        width: 180,
        renderCell: (params: any) =>  (
         <Tooltip title={params.value} >
          <p>{params.value}</p>
         </Tooltip>
        )
      },
      {
        field: 'weight',
        headerName: 'Weight',
        width: 180,
        renderCell: (params: any) =>  (
         <Tooltip title={params.value} >
          <p>{params.value}</p>
         </Tooltip>
        )
      },
      {
        field: 'interactions',
        headerName: 'Interactions',
        width: 220,
        valueFormatter: (params: GridValueFormatterParams) => {
          if(params.value instanceof Array){
            return params.value.join(", ");
          }else{
            return "N/A"
          }
        },
        renderCell: (params: any) =>  (
         <Tooltip title={params.value.join(", ")} >
          <p>{params.value.join(", ")}</p>
         </Tooltip>
        )
      },
      {
        field: 'controls',
        headerName: 'Controls',
        width: 600,
        valueFormatter: (params: GridValueFormatterParams) => {
          if(params.value instanceof Array){
            return params.value.join(", ");
          }else{
            return "N/A"
          }
        },
        renderCell: (params: any) =>  (
         <Tooltip title={params.value.join(", ")} >
          <p>{params.value.join(", ")}</p>
         </Tooltip>
        )
      }
    ];

    return (
      <>
        <DataGrid autoHeight rows={data} columns={columns} className={classes.dataGrid} pageSize={10} getRowId={(row) => row.calculation_hash}/>
      </>
    );
  } else {
    return (<p>No request sent to server</p>);
  }

}