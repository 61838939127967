import React from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-monokai";

export function ReadOnlyYaml(config: string) {
  return (
    <AceEditor
      mode="yaml"
      theme="monokai"
      name="Test"
      value={config}
      readOnly={true}
      fontSize={18}
      width="100%"
    />
  )
}