import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';

import moment from 'moment';
import {useParams, useHistory, useLocation} from "react-router-dom";
import qs from 'query-string';

import {
  Tooltip,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Bar,
  BarChart,
  ReferenceArea
} from 'recharts';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import 'date-fns';

import { useGetBacktestTearsheetQuery } from '../../services/ReportingService';
import { BacktestTearsheetQuery } from '../../services/types';
import { ReadOnlyYaml } from '../../features/config_yaml/ConfigYaml';
import Logo from "../../logo.png";

interface ParamTypes {
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    table: {
      minWidth: 250,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15)
    }
  }),
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function TearSheet(){
  let { id } = useParams<ParamTypes>();
  let queryParams = useQuery();
  const classes = useStyles();
  const history = useHistory()

  const [startDate, setStartDate] = useState(queryParams.get("startDate") || "08/29/2013");
  const [endDate, setEndDate] = useState(queryParams.get("endDate") || "01/01/2017");
  const [benchmarkHash, setBenchmarkHash] = useState(queryParams.get("benchmarkHash") || "");
  const [managmentFee, setManagmentFee] = useState(queryParams.get("managmentFee") || "0");
  const [incentiveFee, setIncentiveFee] = useState(queryParams.get("incentiveFee") || "0");
  const [volAdj, setVolAdj] = useState(false);
  const [showDrawdowns, setShowDrawdowns] = useState(false);


  const defaultQuery: BacktestTearsheetQuery = {
    start_date: startDate,
    end_date: endDate,
    benchmark_pnl_calculation_hash: benchmarkHash,
    management_fee: Number(managmentFee),
    incentive_fee: Number(incentiveFee),
    pnl_calculation_hash: id
  }

  const [query, setQuery] = useState(defaultQuery)
  const { data, error, isLoading, isFetching } = useGetBacktestTearsheetQuery(query)

  function handleRefresh(){
    const params: BacktestTearsheetQuery = {
      start_date: startDate,
      end_date: endDate,
      benchmark_pnl_calculation_hash: benchmarkHash,
      management_fee: Number(managmentFee),
      incentive_fee: Number(incentiveFee),
      pnl_calculation_hash: id
    }
    setQuery(params)
    history.push(
      {
        search: qs.stringify(
          {
            startDate: startDate,
            endDate: endDate,
            benchmarkHash: benchmarkHash,
            managmentFee: managmentFee,
            incentiveFee: incentiveFee
          }
        )
      }
    )
  }

  const optionBar = (
    <Paper elevation={2} style={{padding: 10, margin: 10}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="space-around">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="start-date-picker-inline"
            label="Start Date"
            value={startDate}
            onChange={(date: Date | null) => {if(date){setStartDate(date.toLocaleDateString("en-US"));}}}
            KeyboardButtonProps={{'aria-label': 'change start date'}}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="end-date-picker-inline"
            label="End Date"
            value={endDate}
            onChange={(date: Date | null) => {if(date){setEndDate(date.toLocaleDateString("en-US"));}}}
            KeyboardButtonProps={{'aria-label': 'change end date'}}
          />
          <TextField id="incentive" label="Incentive Fee" value={incentiveFee} onChange={(event) => {setIncentiveFee(event.target.value)}} />
          <TextField id="managment" label="Managment Fee" value={managmentFee} onChange={(event) => {setManagmentFee(event.target.value)}} />
          <Button onClick={handleRefresh}><RefreshIcon fontSize="large" color="primary"/></Button>
        </Grid>
        <Grid>
          <Grid container justifyContent="space-around">
            <TextField fullWidth id="benchmark-pnl-hash" label="Benchmark PnL Hash" value={benchmarkHash} onChange={(event) => {setBenchmarkHash(event.target.value)}} />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  )

  let content = <p>No request sent to server</p>;
  let description = "No data preview avaialble";
  if (isLoading){
    content = (
      <h2>
        Building tear sheet, this takes about 20 seconds...
        <CircularProgress />
      </h2>
    );
  } else if (error){
    content = (
      <div>
        <h3>Error getting tear sheet</h3>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  } else if (data) {
    const {
      performance_metrics,
      returns,
      title,
      date_range,
      posterior_vol,
      posterior_sharpe,
      standardized_returns_bias_test,
      monthly_returns,
      yearly_returns,
      drawdown,
      forecast_configs,
      optimize_config,
      pnl_config,
      underwater
    } = data

    let dataHasBenchmark = false;
    if ("benchmark_returns" in returns[0]){
      dataHasBenchmark = true;
    }

    const configurationAccordion = (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Forecast Configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ReadOnlyYaml(forecast_configs)}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Optimize Configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ReadOnlyYaml(optimize_config)}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>PnL Configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ReadOnlyYaml(pnl_config)}
          </AccordionDetails>
        </Accordion>
      </div>
    );

    let performanceHeaderRows = [<TableCell>Metric</TableCell>, <TableCell>Strategy</TableCell>]
    if (dataHasBenchmark){
      performanceHeaderRows.push(<TableCell>Benchmark</TableCell>)
      performanceHeaderRows.push(<TableCell>Relative</TableCell>)
    }

    const description_elements: Array<string> = [];
    let performanceDataRows = performance_metrics.map((dataRow) => {
      let row = [<TableCell align="right">{dataRow.strategy}</TableCell>];
      if (dataRow.benchmark && dataRow.relative){
        row.push(<TableCell align="right">{dataRow.benchmark}</TableCell>);
        row.push(<TableCell align="right">{dataRow.relative}</TableCell>);
      }
      if (dataRow.metric in ["Total Return", "Sharpe", "Volatility"] ){
        description_elements.push(`${dataRow.metric}: ${dataRow.strategy}`);
      }
      return (
        <TableRow key={dataRow.metric}>
          <TableCell component="th" scope="row">{dataRow.metric}</TableCell>
          {row}
        </TableRow>
      );
    });
    if (description_elements.length > 0){
      description = description_elements.join(", ");
    }

    const performance_sidebar = (
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
            {performanceHeaderRows}
            </TableRow>
          </TableHead>
          <TableBody>
            {performanceDataRows}
          </TableBody>
        </Table>
      </TableContainer>
    );

    let drawdownRows = []
    for (const i in drawdown.Peak){
      drawdownRows.push(
        <TableRow key={`drawdown_${i}`}>
          <TableCell>{`${Number(drawdown.Value[i]).toFixed(2)}%`}</TableCell>
          <TableCell>{moment(drawdown.Peak[i]).format('MM/DD/YY')}</TableCell>
          <TableCell>{moment(drawdown.Valley[i]).format('MM/DD/YY')}</TableCell>
          <TableCell>{drawdown.Days[i]}</TableCell>
          <TableCell>{drawdown.Recovery[i] ? moment(drawdown.Recovery[i]).format('MM/DD/YY') : "-"}</TableCell>
        </TableRow>
      )
    }

    const drawdown_table = (
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Value</TableCell>
              <TableCell>Peak</TableCell>
              <TableCell>Valley</TableCell>
              <TableCell>Days</TableCell>
              <TableCell>Recovery</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drawdownRows}
          </TableBody>
        </Table>
      </TableContainer>
    )

    const areas = [<Area key="returns_area" type="monotone" dataKey="returns" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />];
    if (dataHasBenchmark){
      if (volAdj){
        areas.push(<Area key="benchmark_returns_area_voladj" type="monotone" dataKey="benchmark_returns_voladj" stroke="#8884d8" fillOpacity={1} fill="url(#colorPy)" />)
      } else {
        areas.push(<Area key="benchmark_returns_area" type="monotone" dataKey="benchmark_returns" stroke="#8884d8" fillOpacity={1} fill="url(#colorPy)" />)
      }
    }

    const drawdownRectangles = []
    if (showDrawdowns){
      for (const i in drawdown.Peak){
        let end = drawdown.Recovery[i]
        if (!end){
          end = endDate
        } else {
          end = moment(end).format('MM/DD/YY')
        }
        const start = moment(drawdown.Peak[i]).format('MM/DD/YY');
        drawdownRectangles.push(
          <ReferenceArea
            key={`drawdown_area_${i}`}
            x1={start}
            x2={end}
            fill="red"
            label={`${Number(drawdown.Value[i]).toFixed(2)}%`}
          />
        )
      }
    }

    const returns_chart = (
      <AreaChart width={650} height={450} data={returns}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPy" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPz" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#ff7300" stopOpacity={0}/>
          </linearGradient>
        </defs>

        <XAxis dataKey="ts"/>
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis type="number" label={{ value: "%", angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        {drawdownRectangles}
        {areas}
      </AreaChart>
    );

    let rel_returns_chart = <div />;
    if (dataHasBenchmark){
      const rel_areas = [];
      if (volAdj){
        rel_areas.push(<Area key="rel_returns_area_voladj" type="monotone" dataKey="relative_returns_voladj" stroke="#ff7300" fillOpacity={1} fill="url(#colorPz)" />)
      } else {
        rel_areas.push(<Area key="rel_returns_area" type="monotone" dataKey="relative_returns" stroke="#ff7300" fillOpacity={1} fill="url(#colorPz)" />)
      }
      rel_returns_chart = (
        <AreaChart width={650} height={450} data={returns}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorPz" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#ff7300" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="ts"/>
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis type="number" label={{ value: "%", angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          {rel_areas}
        </AreaChart>
      );
    }

    const underwater_chart = (
      <AreaChart width={650} height={450} data={underwater}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorPz" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#ff7300" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="ts"/>
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis type="number" label={{ value: "%", angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Area type="monotone" dataKey="underwater" stroke="#ff7300" fillOpacity={1} fill="url(#colorPz)" />
      </AreaChart>
    )

    const posterior_sharpe_chart = (
      <LineChart width={600} height={400} data={posterior_sharpe} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
        <XAxis dataKey="ts"/>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis type="number" label={{ value: "Sharpe", angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="six_month" stroke="#82ca9d" dot={false}/>
        <Line type="monotone" dataKey="one_year" stroke="#8884d8" dot={false}/>
        <Line type="monotone" dataKey="two_year" stroke="#ff7300" dot={false}/>
      </LineChart>
    );

    const posterior_vol_chart = (
      <LineChart width={600} height={400} data={posterior_vol} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
        <XAxis dataKey="ts"/>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis type="number" label={{ value: "bps", angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="six_month" stroke="#82ca9d" dot={false}/>
        <Line type="monotone" dataKey="one_year" stroke="#8884d8" dot={false}/>
        <Line type="monotone" dataKey="two_year" stroke="#ff7300" dot={false}/>
      </LineChart>
    );

    const bias_chart = (
      <LineChart width={650} height={450} data={standardized_returns_bias_test}>
        <XAxis dataKey="ts"/>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis type="number" label={{ value: "Rolling std", angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="30D" stroke="#82ca9d" dot={false}/>
        <Line type="monotone" dataKey="50D" stroke="#8884d8" dot={false}/>
        <Line type="monotone" dataKey="100D" stroke="#ff7300" dot={false}/>
      </LineChart>
    );

    let eoy_bars = [<Bar key="eoy_returns" dataKey="returns" fill="#82ca9d" />]
    if (dataHasBenchmark){
      eoy_bars.push(<Bar key="eoy_benchmark_returns" dataKey="benchmark_returns" fill="#8884d8" />)
    }
    const eoy_returns_chart = (
      <BarChart
        width={600}
        height={400}
        data={yearly_returns}
        margin={{top: 5, right: 30, left: 20, bottom: 5}}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ts"/>
        <YAxis type="number" label={{ value: "%", angle: -90, position: 'insideLeft' }} />
        <Legend />
        <Tooltip />
        {eoy_bars}
      </BarChart>
    )

    let eom_bars = [<Bar key="eom_returns" dataKey="returns" fill="#82ca9d" />]
    if (dataHasBenchmark){
      eom_bars.push(<Bar key="eom_benchmark_returns" dataKey="benchmark_returns" fill="#8884d8" />)
    }
    const eom_returns_chart = (
      <BarChart
        width={600}
        height={400}
        data={monthly_returns}
        margin={{top: 5, right: 30, left: 20, bottom: 5}}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ts"/>
        <YAxis type="number" label={{ value: "%", angle: -90, position: 'insideLeft' }} />
        <Legend />
        <Tooltip />
        {eom_bars}
      </BarChart>
    )

    let header = <h3>{title} {date_range} ({id})</h3>
    if (isFetching){
      header = <h3>Refreshing... <CircularProgress/></h3>
    }
    content = (
      <div>
        {header}
        <Divider/>
        {configurationAccordion}
        <Divider/>
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper}>
              <h3>Cummulative Returns {volAdj && "(volatility adjusted)"}</h3>
              <FormControlLabel
                control={
                  <Switch checked={volAdj} onChange={() => {setVolAdj(!volAdj)}} name="Volume Adjusted Returns" />
                }
                label="Volatility Adjusted"
                disabled={!dataHasBenchmark}
              />
              <FormControlLabel
                control={
                  <Switch checked={showDrawdowns} onChange={() => {setShowDrawdowns(!showDrawdowns)}} name="Show Drawdowns" />
                }
                label="Show Drawdowns"
              />
              {returns_chart}
              <Divider />
              {dataHasBenchmark && <h3>Relative Returns {volAdj && "(volatility adjusted)"}</h3>}
              {rel_returns_chart}
            </Paper>
            <Divider/>
            <Paper className={classes.paper}>
              <h3>Underwater</h3>
              {underwater_chart}
            </Paper>
            <Divider/>
            <Paper className={classes.paper}>
              <h3>Bias Test</h3>
              {bias_chart}
            </Paper>
          </Grid>
          <Grid item xs={5}>
            {performance_sidebar}
            <Divider/>
            <h3>Worst Drawdowns</h3>
            {drawdown_table}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <h3>Yearly Returns</h3>
              {eoy_returns_chart}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <h3>Monthly Returns</h3>
              {eom_returns_chart}
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <h3>Posterior Sharpe</h3>
              {posterior_sharpe_chart}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <h3>Posterior Vol</h3>
              {posterior_vol_chart}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <MetaTags>
        <title>Backtest {id} ({startDate} - {endDate})</title>
        <meta property="og:description" content={description} />
        <meta property="og:image" content={Logo} />
      </MetaTags>
      {optionBar}
      {content}
    </div>
  );
}