import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query'
import { reportingApi, reportingProdApi } from '../services/ReportingService';


export const store = configureStore(
  {
    reducer: {
      [reportingApi.reducerPath]: reportingApi.reducer,
      [reportingProdApi.reducerPath]: reportingProdApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(reportingApi.middleware),
  }
);

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
