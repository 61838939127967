import React from 'react';
import {useParams} from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReadOnlyYaml } from '../../features/config_yaml/ConfigYaml';

import { useGetOrthogonalModelConfigQuery } from '../../services/ReportingService';

interface ParamTypes {
  id: string;
}

export default function OrthogonalConfig() {
  // Using a query hook automatically fetches data and returns query values
  let { id } = useParams<ParamTypes>();
  const { data, error, isLoading } = useGetOrthogonalModelConfigQuery(id)

  if (isLoading){
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } else if (error){
    console.log(error)
    return (
      <div>
        <h3>Error getting orthogonal configuration</h3>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  } else if (data) {
    return (
      <div>
        <Typography variant="h5" component="h2">
          Calculation ID:
        </Typography>
        {ReadOnlyYaml(data)}
      </div>
    );
  }
  return <p>Error generating configuration report</p>;
}
