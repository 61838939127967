import React from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';

import { Tooltip, Bar, BarChart, XAxis, YAxis, CartesianGrid } from 'recharts';

import { useGetBacktestsStubListQuery } from '../../services/ReportingService'


export default function BacktestStubList() {
  // Using a query hook automatically fetches data and returns query values
  const { data, error, isLoading } = useGetBacktestsStubListQuery()

  if (isLoading){
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } else if (error){
    console.log(error)
    return (
      <div>
        <h3>Error getting backtest list</h3>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  } else if (data) {
    const { backtests, backtests_by_week } = data
    const columns: GridColDef[] = [
      {
        field: "hash",
        headerName: "Tear Sheet",
        width: 280,
        renderCell: (params) => {
          return (
            <Link to={`/backtest/tear/${params.value}`}>
              {params.value}
            </Link>
          )
        }
      },
      {
        field: 'created',
        headerName: 'Created Ts',
        width: 220,
        valueFormatter: (params: GridValueFormatterParams) => (new Date(String(params.value))).toLocaleString()
      },
      {
        field: 'start',
        headerName: 'Series Start',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => (new Date(String(params.value))).toLocaleDateString("en-US")
      },
      {
        field: 'end',
        headerName: 'Series End',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => (new Date(String(params.value))).toLocaleDateString("en-US")
      }
    ];

    const backtests_by_week_series: Array<any> = []
    for (let [ts, value] of Object.entries(backtests_by_week)){
      backtests_by_week_series.push({ts: moment(ts).format("MM/DD/YYYY"), count: value})
    }

    return (
      <div>
        <DataGrid autoHeight rows={backtests} columns={columns} pageSize={10} getRowId={(row) => row.hash}/>
        <h3>Backtests by week</h3>
        <BarChart
          width={800}
          height={300}
          data={backtests_by_week_series}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="ts"/>
          <YAxis type="number" label={{ value: "# of Backtests", angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </div>
    );
  } else {
    return (<p>No request sent to server</p>);
  }

}