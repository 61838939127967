import React, { useState } from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';

import Button from '@material-ui/core/Button';

import { useGetTimeseriesRunsQuery } from '../../services/ReportingService';
import { TimeseriesRunListQuery }from '../../services/types';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import 'date-fns';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataGrid: {
      width: "1400px"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    }
  })
);

export default function TimeseriesRunList() {
  // Using a query hook automatically fetches data and returns query values

  const [startDate, setStartDate] = useState(
    moment().subtract('months', 1).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(moment().format("MM/DD/YYYY"));
  const [queryField, setQueryField] = useState("created");

  const query: TimeseriesRunListQuery = {
    "queryField": queryField,
    "startDate": startDate,
    "endDate": endDate
  }

  console.log(query)

  const { data, error, isLoading } = useGetTimeseriesRunsQuery(query);

  const classes = useStyles();

  if (isLoading){
    return (
      <CircularProgress />
    );
  } else if (error){
    console.log(error);
    return (
      <div>
        <h3>Error getting run list</h3>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  } else if (data) {
    const columns: GridColDef[] = [
      {
        field: "hash",
        headerName: "Run Hash",
        sortable: false,
        width: 280,
        renderCell: (params) => {
          return (
            <Button component={Link} to={`/timeseries_runs/config/${params.row.hash}`} size="small" color="primary">
              {params.row.hash}
            </Button>
          )
        }
      },
      {
        headerName: 'class',
        field: 'run_id',
        width: 260
      },
      {
        field: 'created',
        headerName: 'Created',
        width: 180,
        valueFormatter: (params: GridValueFormatterParams) => (new Date(String(params.value))).toLocaleString()
      },
      {
        field: 'last_read',
        headerName: 'Last Read',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => (new Date(String(params.value))).toLocaleDateString("en-US")
      },
      {
        headerName: 'models',
        field: 'metamodel_records',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => Number(params.value as number).toLocaleString()
      },
      {
        headerName: 'model size',
        field: 'metamodel_size',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => (Number(params.value as number) / (1024 * 1024 * 1024)).toLocaleString() + "GB"
      },
      {
        headerName: 'features',
        field: 'feature_records',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => Number(params.value as number).toLocaleString()
      },
      {
        headerName: 'aggregates',
        field: 'aggregateseries_records',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => Number(params.value as number).toLocaleString()
      }
    ];
    return (
      <Container className={classes.dataGrid} >
        <Paper elevation={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date-picker-inline"
                label="Start Date"
                value={startDate}
                onChange={(date: Date | null) => {console.log(`start ${date}`);if(date){setStartDate(date.toLocaleDateString("en-US"));}}}
                KeyboardButtonProps={{'aria-label': 'change start date'}}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="end-date-picker-inline"
                label="End Date"
                value={endDate}
                onChange={(date: Date | null) => {console.log(`end ${date}`);if(date){setEndDate(date.toLocaleDateString("en-US"));}}}
                KeyboardButtonProps={{'aria-label': 'change end date'}}
              />
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Query Field</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={queryField}
                  onChange={(event) => setQueryField(event.target.value as string)}
                >
                  <MenuItem value="created">Created ts</MenuItem>
                  <MenuItem value="last_read">Last read ts</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </MuiPickersUtilsProvider>
        </Paper>
        <Paper elevation={1}>
          <DataGrid autoHeight rows={data} columns={columns} pageSize={10} getRowId={(row) => row.hash}/>;
        </Paper>
      </Container>
    );
  } else {
    return (<p>No request sent to server</p>);
  }

}